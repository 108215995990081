import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Leftmenu, Topmenu } from '../interface'
import systemIcon from '../assets/系统管理.png'
import operationIcon from '../assets/运营管理.png'
import memberIcon from '../assets/会员管理.png'
import userIcon from '../assets/用户管理.png'
import productIcon from '../assets/商品管理.png'
import productGroup from '../assets/商品组合包.png'
import messageSetting from '../assets/短信配置.png'
import messageSign from '../assets/短信签名.png'
import messageTemplate from '../assets/短信模板.png'
import channelIcon from '../assets/推广渠道.png'
import system from '../assets/系统.png'
import languageIcon from '../assets/语言.png'
import systemSetting from '../assets/系统配置.png'
import consumablesIcon from '../assets/样品及消耗品管理.png'
import companyIcon from '../assets/公司管理.png'
import applicationIcon from '../assets/应用管理.png'
import paymentIcon from '../assets/支付配置.png'
import payTypeIcon from '../assets/15-支付类型表-内页.png'
import currencryIcon from '../assets/支付_货币_人民币02_ (1).png'
import developIcon from '../assets/开发管理.png'
import developing from '../assets/2.png'
export const homeStore = defineStore(
    'homeStore',
    () => {
        //https://inapp.v3.zh.biggerlens.com:5888
        //state
        const defaultRequest = ref<string>('https://inapp.v3.zh.biggerlens.com:5888') //默认接口 国内
        const isShowInviteBox = ref<boolean>(false)
        const leftMenuDefaultActive = ref<string>('1') //左侧菜单默认选中
        const topMenuDefaultActive = ref<string>('2') //顶部菜单默认选中
        const changePwdDialog = ref<boolean>(false) //修改密码弹窗
        const isLoggedOut = ref<boolean>(false)
        const leftList = ref<Leftmenu[]>([
            {
                menuName: '商品管理',
                menuUrl: '/productManagement',
                index: '1',
                icon: productIcon
            },
            {
                menuName: '商品组管理',
                menuUrl: '/productGroupManagement',
                index: '2',
                icon: productGroup
            },

            {
                menuName: '功能点',
                menuUrl: '/consumables',
                index: '3',
                icon: consumablesIcon
            }
        ])
        const topList = ref<Topmenu[]>([
            {
                menuName: '系统管理',
                leftmenus: [
                    {
                        menuName: '渠道',
                        menuUrl: '/channelManagement',
                        index: '1',
                        icon: channelIcon
                    },
                    {
                        menuName: '系统',
                        menuUrl: '/systemManagement',
                        index: '2',
                        icon: system
                    },
                    {
                        menuName: '语言',
                        menuUrl: '/languageManagement',
                        index: '3',
                        icon: languageIcon
                    },
                    {
                        menuName: '公司',
                        menuUrl: '/companys',
                        index: '4',
                        icon: companyIcon
                    },
                    {
                        menuName: '应用',
                        menuUrl: '/application',
                        index: '5',
                        icon: applicationIcon
                    },

                    {
                        menuName: '支付类型',
                        menuUrl: '/paymentType',
                        index: '6',
                        icon: payTypeIcon
                    },
                    {
                        menuName: '支付货币',
                        menuUrl: '/currency',
                        index: '7',
                        icon: currencryIcon
                    }
                ],
                index: '1',
                icon: systemIcon
            },
            {
                menuName: '运营管理',
                leftmenus: [
                    {
                        menuName: '商品管理',
                        menuUrl: '/productManagement',
                        index: '1',
                        icon: productIcon
                    },
                    {
                        menuName: '商品组管理',
                        menuUrl: '/productGroupManagement',
                        index: '2',
                        icon: productGroup
                    },

                    {
                        menuName: '功能点',
                        menuUrl: '/consumables',
                        index: '3',
                        icon: consumablesIcon
                    }
                ],
                index: '2',
                icon: operationIcon
            },
            {
                menuName: '应用管理',
                leftmenus: [
                    {
                        menuName: '消息配置',
                        menuUrl: '/messageManagement',
                        index: '1',
                        icon: messageSetting
                    },
                    {
                        menuName: '消息签名',
                        menuUrl: '/messageSignature',
                        index: '2',
                        icon: messageSign
                    },
                    {
                        menuName: '消息模板',
                        menuUrl: '/messageTemplate',
                        index: '3',
                        icon: messageTemplate
                    },
                    {
                        menuName: '应用配置',
                        menuUrl: '/productBindInfo',
                        index: '4',
                        icon: systemSetting
                    }
                ],
                index: '3',
                icon: developIcon
            },
            {
                menuName: '开发管理',
                leftmenus: [
                    {
                        menuName: '支付配置',
                        menuUrl: '/payment',
                        index: '1',
                        icon: paymentIcon
                    }
                ],
                index: '4',
                icon: developing
            },
            {
                menuName: '会员管理',
                leftmenus: [
                    {
                        menuName: '会员信息',
                        menuUrl: '/member',
                        index: '1',
                        icon: productIcon
                    }
                ],
                index: '5',
                icon: memberIcon
            },
            {
                menuName: '用户管理',
                leftmenus: [],
                index: '6',
                icon: userIcon
            }
        ])

        //actions
        const resetMenu = () => {
            leftMenuDefaultActive.value = '1'
            topMenuDefaultActive.value = '2'
        }

        const routerSetIndex = (index: string) => {
            leftMenuDefaultActive.value = index
        }

        const routerSetTopIndex = (index: string) => {
            topMenuDefaultActive.value = index
        }
        const resetLoggout = (flag: boolean) => {
            isLoggedOut.value = flag
        }
        return {
            leftMenuDefaultActive,
            isLoggedOut,
            topMenuDefaultActive,
            leftList,
            changePwdDialog,
            topList,
            isShowInviteBox,
            defaultRequest,
            resetMenu,
            routerSetIndex,
            routerSetTopIndex,
            resetLoggout
        }
    },
    {
        persist: {
            enabled: true,
            strategies: [
                {
                    key: 'homeStore',
                    storage: sessionStorage
                }
            ]
        }
    }
)
