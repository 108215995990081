<template>
    <div class="main_box">
        <div class="main_box_content">
            <header>
                <div class="search_box"></div>
                <div class="action_box">
                    <serach :list="searchData" @changeTitle="changeTitle" @seraching="seraching" @judgeSearch="judgeSearch" />
                    <el-button type="primary" size="small" @click="addProductInfo" v-if="showadd">添加</el-button>
                </div>
            </header>
            <div class="infoBox" v-if="showInfo" v-loading="isloadingInfobox">
                <template v-if="empytInfo">
                    <div class="no-data-placeholder">
                        <img src="../../assets/无数据.png" alt="No Data Icon" />
                        <p>暂无数据</p>
                    </div>
                </template>
                <template v-else>
                    <div class="infoBox_content" v-for="(value, key, index) in info" :key="index">
                        <span>{{ key }}</span>
                        <span>{{ info[key] }}</span>
                    </div>
                </template>
            </div>
            <setInfoheader :headerData="tableHeader" />

            <div class="tbody_box" v-loading="loading">
                <template v-if="tableData.length == 0">
                    <div class="no-data-placeholder">
                        <img src="../../assets/无数据.png" alt="No Data Icon" />
                        <p>暂无数据</p>
                    </div>
                </template>
                <draggable v-else v-model="tableData" handle=".drag_icon" @start="dragStart" @end="dragEnd">
                    <template #item="{ element }">
                        <tableRow @editor="editor" @deleted="deleted" :app="element" :header="tableHeader" />
                    </template>
                </draggable>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'

import setInfoheader from '../../components/productSetInfo/setInfoheader.vue'
import tableRow from '../../components/table/rowComponent.vue'
import draggable from 'vuedraggable'

import serach from '../table/serachComponent.vue'

const emit = defineEmits<{
    (event: 'editor', info: any): void
    (event: 'deleted', info: any): void

    (event: 'add'): void
    (event: 'serach', data: any, type: string): void
    (event: 'judgeSearch', mark: string): void
    (event: 'changeTitle', item: any): void
}>()
const props = defineProps<{
    searchData: any
    tableHeader: any
    tableData: any
    loading: boolean
    showInfo: boolean

    info: any
    showadd: boolean
    isloadingInfobox: boolean
}>()

const empytInfo = computed(() => {
    const list = Object.keys(props.info)
    if (list.length) {
        return false
    } else {
        return true
    }
})

const editor = (info: any) => {
    emit('editor', info)
}

const deleted = (info: any) => {
    emit('deleted', info)
}

const addProductInfo = (): void => {
    emit('add')
}

const seraching = (info: any, type: string) => {
    emit('serach', info, type)
}

const judgeSearch = (info: any, type: string) => {
    emit('judgeSearch', type)
}

const changeTitle = (title: any) => {
    emit('changeTitle', title)
}

const dragStart = (e: any) => {
    console.log('开始拖拽', e.item['_underlying_vm_'])
}
const dragEnd = (e: any) => {
    console.log('结束拖拽', e.item['_underlying_vm_'])
}
const tableData = computed(() => props.tableData)
onMounted(() => {})
</script>

<style scoped lang="scss">
.main_box {
    width: 100%;
    min-width: 1461px;
    height: 790px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px;
    .main_box_content {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #ccc;
        display: flex;
        flex-direction: column;
        .infoBox {
            box-sizing: border-box;
            width: 100%;
            height: 300px;
            background: white;
            display: flex;
            flex-direction: column;
            align-content: flex-start;
            flex-wrap: wrap;
            padding: 10px; /* 添加内边距 */
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
            border-radius: 8px; /* 添加圆角 */
            position: relative;
            .infoBox_content {
                width: 300px; /* 使用100%宽度以适应父容器 */
                display: flex;
                justify-content: space-between;
                align-items: center; /* 垂直居中对齐 */
                padding: 10px; /* 增加内边距 */
                margin-bottom: 10px; /* 增加元素之间的间距 */
                // background: #f0f8ff; /* 设置背景为白色 */
                // border: 1px solid #ccc; /* 添加边框 */
                border-radius: 4px; /* 添加圆角 */
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); /* 添加阴影 */
                margin-right: 30px;
                transition: all 0.3s ease;
                &:hover {
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* 添加阴影 */
                }
                &:not(:last-child) {
                    border-bottom: 1px solid #eee; /* 修改为边框样式，增强视觉分隔 */
                }
            }
            .no-data-placeholder {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                /* Full height to center the message */
                text-align: center;
                color: #666;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            .no-data-placeholder img {
                width: 100px;
                /* Size of the icon or image */
                height: auto;
                margin-bottom: 20px;
            }

            .no-data-placeholder p {
                font-size: 18px;
                margin: 0;
            }
        }

        header {
            box-sizing: border-box;
            padding: 5px;
            .search_box {
                display: flex;
                .search_item {
                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                }
            }
        }

        .tbody_box {
            width: 100%;
            flex-grow: 1;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 5px;
                /* Width of the scrollbar */
            }

            &::-webkit-scrollbar-track {
                background: #fff;
                /* Track color */
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: #888;
                /* Scrollbar color */
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #555;
                cursor: pointer;
                /* Hover color */
            }
            .no-data-placeholder {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                /* Full height to center the message */
                text-align: center;
                color: #666;
            }

            .no-data-placeholder img {
                width: 100px;
                /* Size of the icon or image */
                height: auto;
                margin-bottom: 20px;
            }

            .no-data-placeholder p {
                font-size: 18px;
                margin: 0;
            }
        }
    }
}
</style>
