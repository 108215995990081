<template>
    <div class="serach_box">
        <el-form-item class="searchItem" v-for="item in list" :key="item.text" :label="formateLabel(item)">
            <el-input @input="seachingText(item)" style="width: 200px" v-if="item.type === 'input'" v-model="item.value" clearable></el-input>
            <el-select :clearable="item.isClearable" @change="seachingSelect(item)" style="width: 200px" filterable v-else-if="hideSelect(item)" v-model="item.value" placeholder="请选择">
                <el-option v-for="el in item.options" :key="el.value" :label="item.text === '应用' ? `${el.label} [appId:${el.value}]` : el.label" :value="el.value"></el-option>
            </el-select>
            <div class="selectTitleInput" v-else-if="item.type === 'selectTitleInput'">
                <el-dropdown>
                    <span class="el-dropdown-link">
                        {{ item.titleText }}
                        <el-icon class="el-icon--right">
                            <arrow-down />
                        </el-icon>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item v-for="title in item?.titleSelectList" @click="changeTitle(title)">{{ title.text }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <el-input class="selectinput" @input="seachingText(item)" style="width: 200px" v-model="item.value" clearable></el-input>
            </div>
        </el-form-item>
    </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
interface searchItem {
    text: string
    value: any
    options?: any
    type: string
    mark: string
    isClearable?: boolean
    titleSelectList?: any
    titleText?: string
    emptyHide?: boolean
}
const props = defineProps<{ list: searchItem[] }>()
const emit = defineEmits<{
    (event: 'seraching', data: any, type: string): void
    (event: 'judgeSearch', data: any, type: string): void
    (event: 'changeTitle', title: any): void
}>()

let time: any
const seachingText = (item: any) => {
    clearTimeout(time)
    time = setTimeout(() => {
        emit('seraching', item.value, item.mark)
        emit('judgeSearch', item.value, item.mark)
    }, 500)
}
const seachingSelect = (item: any) => {
    emit('seraching', item.value, item.mark)
    emit('judgeSearch', item.value, item.mark)
}

const changeTitle = (title: any) => {
    emit('changeTitle', title)
}

const formateLabel = (item: any) => {
    if (item.type === 'selectTitleInput') {
        return ''
    } else {
        if (item.emptyHide) {
            if (!item.options.length) {
                return ''
            } else {
                return item.text
            }
        } else {
            return item.text
        }
    }
}
const hideSelect = (item: any) => {
    if (item.type === 'select') {
        if (item.emptyHide) {
            if (!item.options.length) {
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    } else {
        false
    }
}
onMounted(() => {})
</script>

<style lang="scss" scoped>
.serach_box {
    display: flex;
    flex-wrap: wrap;
    .searchItem {
        margin-right: 20px;
        .selectTitleInput {
            display: flex;
            align-items: center;
            .selectinput {
                margin-left: 10px;
            }
        }
    }
}
</style>
