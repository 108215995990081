<template>
    <div class="table_row" @mouseenter="showDrage = true" @mouseleave="showDrage = false">
        <div class="table_cell" v-for="(item, index) in header" :key="item.mark">
            <div class="drag_icon_box" v-if="!index">
                <img class="drag_icon" v-show="showDrage" src="../../assets//可拖动.png" alt="" />
            </div>
            <!-- <el-checkbox class="check_box" size="small" v-model="language.isChecked" v-if="!index"
                @change="checkRow" /> -->

            <div class="cell_content" v-if="item.mark === 'createTime'">
                <p>{{ formateDate(app[item.value]) }}</p>
            </div>
            <div class="cell_content" v-if="item.mark === 'isDecimal'">
                <p>{{ app[item.value] === true ? '是' : '否' }}</p>
            </div>
            <div class="cell_content" v-else-if="item.mark === 'status'">
                <p>
                    <span class="statusItem" :class="{ unactiveStatusItem: app[item.value] === 0 }">{{ formateStatus(app[item.value]) }}</span>
                </p>
            </div>

            <div class="cell_content" v-else-if="item.mark === 'actions'">
                <el-button type="primary" size="small" @click="goEditor">编辑</el-button>
                <el-button type="danger" size="small" @click="delected">删除</el-button>
            </div>
            <div class="cell_content" v-else>
                <p>{{ app[item.mark] ? app[item.mark] : '--' }}</p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { ElMessageBox } from 'element-plus'
import dayjs from 'dayjs'

const props = defineProps<{ app: any; header: any }>()

const emit = defineEmits<{ (event: 'editor', info: any): void; (event: 'deleted', id: any): void }>()

const showDrage = ref<boolean>(false)

const formateDate = (date: string) => {
    if (!date) return '--'
    return dayjs(date).format('YYYY-MM-DD')
}
const formateStatus = (status: number) => {
    return status === 1 ? '启用' : '禁用'
}

const goEditor = (): void => {
    console.log('编辑')
    emit('editor', JSON.parse(JSON.stringify(props.app)))
}

const delected = () => {
    const delectedItem = props.app

    ElMessageBox.confirm('确定删除该应用吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        emit('deleted', delectedItem)
    })
}
</script>

<style scoped lang="scss">
.table_row {
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    &:hover {
        background-color: #f5f5f5;
    }
    .table_cell {
        display: flex;
        flex: 1;
        align-items: center;
        box-sizing: border-box;
        padding: 10px 5px;
        font-size: 16px;
        .drag_icon_box {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            .drag_icon {
                width: 20px;
                cursor: pointer;
            }
        }
        .cell_content {
            text-align: center;
            display: flex;
            width: 100%;
            align-items: center;
            height: 100%;
            justify-content: center;
            p {
                width: 100%;
                word-break: break-all;
                font-size: 12px;
                white-space: normal;
                .system_item {
                    padding: 5px;
                    border-radius: 5px;
                    background: #409eff;
                    color: #fff;
                    // width: max-content;
                }
                .AndroidStyle {
                    background: #ffd700;
                }
                .webStyle {
                    background: #67c23a;
                }
                .iOSStyle {
                    background: #000000;
                }
                .WindowsStyle {
                    background: #409eff;
                }
                .statusItem {
                    background: #67c23a;
                    color: #fff;
                    padding: 5px;
                    border-radius: 5px;
                }
                .unactiveStatusItem {
                    background: #f56c6c;
                    color: #fff;
                }
            }
        }
        .system_item_box {
            p {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 5px;
            }
            .onlyOne {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .empty {
                grid-template-columns: none;
            }
        }
    }
}
</style>
