import { createApp } from 'vue'

import App from './App.vue'
import router from './router/index'
import pinia from './store/index'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css' // 引入 Element Plus 样式
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './assets/css/progress.css'
import MyTable from './components/table/tableComponent.vue'

const app = createApp(App)
app.component('MyTable', MyTable)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(ElementPlus, {
    locale: zhCn
})
app.use(pinia)
app.use(router)
app.mount('#app')
