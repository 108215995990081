import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import Cookies from 'js-cookie'
import { start, close } from '../utils/nprogess'
import { homeStore } from '../store/home'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../view/home.vue'),
        redirect: '/productManagement',
        children: [
            {
                path: '/productManagement',
                name: 'productManagement',
                component: () => import(/* webpackChunkName: "productManagement" */ '../view/rightcontent/productManagement.vue')
            },
            {
                path: '/productGroupManagement',
                name: 'productGroupManagement',
                component: () => import(/* webpackChunkName: "productGroupManagement" */ '../view/rightcontent/productGroupManagement.vue')
            },
            {
                path: '/productBindInfo',
                name: 'productBindInfo',
                component: () => import(/* webpackChunkName: "productBindInfo" */ '../view/rightcontent/productBindInfo.vue')
            },
            {
                path: '/messageManagement',
                name: 'messageManagement',
                component: () => import(/* webpackChunkName: "messageManagement" */ '../view/rightcontent/messageManagement.vue')
            },
            {
                path: '/messageSignature',
                name: 'messageSignature',
                component: () => import(/* webpackChunkName: "messageSignature" */ '../view/rightcontent/messageSign.vue')
            },
            {
                path: '/messageTemplate',
                name: 'messageTemplate',
                component: () => import(/* webpackChunkName: "messageTemplate" */ '../view/rightcontent/messageTemplate.vue')
            },
            {
                path: '/channelManagement',
                name: 'channelManagement',
                component: () => import(/* webpackChunkName: "channelManagement" */ '../view/rightcontent/channelManagement.vue')
            },
            {
                path: '/systemManagement',
                name: 'systemManagement',
                component: () => import(/* webpackChunkName: "systemManagement" */ '../view/rightcontent/systemManagement.vue')
            },
            {
                path: '/languageManagement',
                name: 'languageManagement',
                component: () => import(/* webpackChunkName: "languageManagement" */ '../view/rightcontent/languageManagement.vue')
            },

            {
                path: '/consumables',
                name: 'consumables',
                component: () => import(/* webpackChunkName: "consumablesManagement" */ '../view/rightcontent/consumablesManagememt.vue')
            },
            {
                path: '/companys',
                name: 'companys',
                component: () => import(/* webpackChunkName: "companys" */ '../view/rightcontent/companysManagement.vue')
            },
            {
                path: '/application',
                name: 'application',
                component: () => import(/* webpackChunkName: "application" */ '../view/rightcontent/applicationManagement.vue')
            },
            {
                path: '/payment',
                name: 'payment',
                component: () => import(/* webpackChunkName: "payment" */ '../view/rightcontent/paymentManagement.vue')
            },
            {
                path: '/member',
                name: 'member',
                component: () => import(/* webpackChunkName: "member" */ '../view/rightcontent/member/memberManagement.vue')
            },
            {
                path: '/paymentType',
                name: 'paymentType',
                component: () => import(/* webpackChunkName: "paymentType" */ '../view/rightcontent/paymentTypeManagement.vue')
            },
            {
                path: '/currency',
                name: 'currency',
                component: () => import(/* webpackChunkName: "currency" */ '../view/rightcontent/currencyManagement.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../view/login.vue')
    },
    {
        path: '/init',
        name: 'init',
        component: () => import(/* webpackChunkName: "init" */ '../view/inituser.vue')
        // beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
        //     // const token = Cookies.get('member_token')
        //     // if (token) {
        //     //     next('/')
        //     // } else {
        //     //     next('/login')
        //     // }
        // }
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    start()
    const token = Cookies.get('member_token')
    if (token) {
        if (to.path === '/login') {
            next('/')
        } else {
            next()
        }
    } else {
        if (to.path === '/login') {
            next()
        } else {
            next('/login')
        }
        const useHomeStore = homeStore()
        useHomeStore.resetMenu()
    }
})

router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    //设置路由激活菜单样式
    if (window.history.state.forward) {
        const useHomeStore = homeStore()
        const item = useHomeStore.leftList.find((el: any) => el.menuUrl === to.path)
        if (item) {
            useHomeStore.routerSetIndex(item.index)
        }
        const topItem = useHomeStore.topList.find((el: any) => {
            return el.leftmenus.find((child: any) => child.menuUrl === to.path)
        })
        console.log('routerForward', window.history.state)
        if (topItem) {
            useHomeStore.routerSetTopIndex(topItem.index)
            useHomeStore.leftList = topItem.leftmenus
        }
    }

    // 关闭进度条
    close()
})

export default router
