<template>
    <div class="setInfoheader">
        <div class="th_item" v-for="item in headerData" :key="item.value">
            {{ item.name }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
const props = defineProps<{ headerData: any }>()
onMounted(() => {
    console.log('表头', props.headerData)
})
</script>

<style scoped lang="scss">
.setInfoheader {
    width: 100%;
    height: 30px;
    background: #e2f0ff;
    display: flex;
    font-size: 14px;
    box-sizing: border-box;
    padding-right: 7px;
    .th_item {
        flex: 1;
        height: 100%;
        text-align: center;
        line-height: 30px;
        &:not(:last-child) {
            border-right: 1px solid #e8e8e8;
        }
    }
}
</style>
